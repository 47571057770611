<template>
  <section class="hero is-fullheight has-background-light">
    <div class="hero-head"></div>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-8-tablet is-5-desktop">
            <h1 class="title">Loading</h1>
            <progress v-if="showProgress" class="progress is-info"></progress>
            <p class="notification is-danger" v-if="showNotification">
              {{ notificationMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VueRouter from "vue-router";
const { isNavigationFailure } = VueRouter;

export default {
  name: "Dispatcher",
  computed: {
    ...mapState(["showNotification", "notificationMessage","instanceConfig"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"])
  },
  props: ["instanceId"],
  data() {
    return {
      showProgress: true
    }
  },
  async mounted() {
    await this.fetchPaymentPortalInstanceConfig(this.instanceId);

    if( !this.showNotification ) {
      try {
        let routeObj = { name: "home" };
        if( this.instanceConfig.mode == "GUEST" ) {
          routeObj = { name: "guest.lookup" };
        }
        await this.$router.push(routeObj);

      } catch (failure) {
        if (!isNavigationFailure(failure)) { // ignore valid redirect
          this.errorMessage = failure.message;
        }
      }
    }
    this.showProgress = false;
  }
}
</script>

<style scoped>

</style>